<template>
  <div class="wrap">
    <van-nav-bar :title="$t('page.commissionRecord')">
      <van-icon slot="left" name="arrow-left" color="#fff" @click="$router.back()" />
    </van-nav-bar>
    <van-list v-model="loading" :finished="finished" :finished-text="$t('Hint.notMore') + '...'"
      :loading-text="$t('Hint.loading')" @load="getList">
      <div v-for="(item, index) in list" :key="index" class="good_content">
        <div class="bottom">
          <div class="left">
            <img :src="`${domain}${item.picture}`" alt="" />
          </div>
          <div class="right">
            <div class="return">
              {{ $t("newAdd.ExpectedReturn") }}&nbsp;
              {{ toFixed((item.total_price * item.rate) / 100, 2) }}
            </div>
            <div class="desc">
              {{ item.title }}
            </div>
            <div class="time">{{ $format(item.indate) }}</div>
          </div>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { NavBar, Icon, Toast, List } from "vant";
import { $post, $get, domain } from "../../utils/request";
import { formatDate } from "../../utils/tools";
export default {
  components: { [NavBar.name]: NavBar, [Icon.name]: Icon, [List.name]: List },
  data() {
    return {
      list: [],
      loading: true,
      finished: false,
      currentPage: 1,
      firstGet: true,
      domain,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      try {
        const res = await $get("/task/getTaskOrder", {
          status: 2,
          page: this.currentPage,
        });
        const { data, ret, msg } = res.data;
        this.loading = false;
        if (ret == 1) {
          if (!data.hasMorePages) {
            this.finished = true;
          }
          this.currentPage++;
          if (this.firstGet) {
            this.list = data.lists;
            this.firstGet = false;
            return;
          }
          this.list = [...this.list, data.lists];
          return;
        }
        Toast(msg);
      } catch (error) { }
    },
    getStatusText(action) {
      switch (action) {
        case 1:
          return this.$t("status.into");
        case 2:
          return this.$t("status.out");
        default:
          return "";
      }
    },
    dateEvent(indate) {
      return formatDate("", indate);
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);
.wrap {
  min-height: 100vh;
  // background: #f0ebef;
  
}

.list_item {
  margin: calc(9rem / 16) calc(6rem / 16) 0;
  // background: #fff;
  background: @bg;
  border-radius: 4px;
  padding: calc(10rem / 16) 0 calc(21rem / 16) calc(15rem / 16);

  .title {
    color: var(--theme);
    font-size: calc(18rem / 16);
    margin-bottom: calc(12rem / 16);
  }

  .row {
    display: flex;
    margin-top: calc(9rem / 16);

    &:nth-child(1) {
      margin-top: 0;
    }

    >div {
      width: 50%;
    }

    .left {
      font-size: calc(14rem / 16);
      // color: #707070;
      color: @sizeColor;
    }

    .right {
      font-size: calc(16rem / 16);
    }
  }
}

.good_content {
  height: 100%;
  padding: 0 calc(16rem / 16);
  // background: #fff;
  background: @bg;
  margin: calc(10rem / 16);
  border-radius: 6px;

  >.bottom {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: calc(8rem / 16) 0;
    line-height: 1;

    >.left {
      max-width: 40%;

      >img {
        width: 22vw;
        height: 24vw;
        border-radius: 2px;
      }
    }

    >.right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      max-width: 60%;

      >.return {
        color: var(--theme);
        font-size: calc(14rem / 16);
        margin-bottom: calc(12rem / 16);
      }

      >.desc {
        // color: #666;
        color: @sizeColor;
        font-size: calc(12rem / 16);
        margin-bottom: calc(38rem / 16);
        max-width: 100%;
        word-break: break-all;
      }

      >.time {
        // color: #999;
        color: @sizeColor;
        font-size: calc(12rem / 16);
      }
    }
  }
}</style>